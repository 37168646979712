import React from "react"
import Layout from "../components/layout"
import ImageMeta from "../components/ImageMeta"
import SEO from "../components/seo"
import Button from "@components/Button/Button"
import TheaterVideo from "@components/Theater/TheaterVideo"

const wisdomTeethData = require("../_archives/procedures/wisdom-teeth-removal-middlesex-nj.json")

const WisdomStepOnePage = () => {
  let language = "en"

  return (
    <Layout pageTitle="procedure-page" language={language}>
      <SEO
        lang={language}
        title="Watch the Wisdom For Wisdom Video"
        description="Follow the Wisdom For Wisdom steps to apply"
      />

      <div className="joshua-tree-content program-page-wisdom">
        <div className="columns video-page top-section white-back has-text-centered">
          <div className="column">
            <h1>Step 1: Watch the Video</h1>
          </div>
        </div>

        <section className="body-section" style={{ marginTop: 0 }}>
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${wisdomTeethData.youtube}`}
                controls={true}
                playing={true}
                overVideo={true}
                hasWatchVideo={true}
                buttonClass="contained"
              >
                <ImageMeta
                  cloudName="nuvolum"
                  publicId="OSG/DEV/wisdom-teeth-removal-procedure-desktop"
                  width="auto"
                  responsive
                  responsiveUseBreakpoints="true"
                />
              </TheaterVideo>
            </div>
            <div className="column is-2"></div>
          </div>
        </section>

        <section className="body-section" style={{ paddingTop: 0 }}>
          <div className="columns">
            <div className="column is-6"></div>
            <div className="column">
              <h3>
                Watch this video about wisdom teeth carefully. After you watch
                it, we will quiz you on what you learned in step 2, so pay
                attention!
              </h3>
              <div style={{ textAlign: "center" }}>
                <Button
                  className="wisdom-button contained"
                  buttonText="Step 2: Take the Quiz"
                  href="/w4w-step-2"
                />
              </div>
            </div>
            <div className="column is-6"></div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default WisdomStepOnePage
